<template>
  <section class="box-height qiyuFarDom" style="height: 91vh; overflow: auto">
    <resumeDetails
      :hover="hoverInfo"
      :resumeDetailId="resumeId"
      :detailShowEntry="detailShowEntry"
      :repeatResume="repeatResume"
    ></resumeDetails>
  </section>
</template>
<script>
import resumeDetails from "../../components/resumeDetail";
export default {
  components: {
    resumeDetails,
  },
  data() {
    return {
      repeatResume: { type: "列表", valId: "" },
      hoverInfo: { matchAll: true, majorSearch: false, hover: true },
      resumeId: null,
      detailShowEntry: "",
    };
  },
  created() {
    this.resumeId = this.$route.query.resumeId; //122
    this.detailShowEntry = this.$route.query.entry; //122
  },
};
</script>